import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'franchiseeDropdown',
    'name',
    'email',
    'username',
    'franchiseeType',
    'uid',
    'parentUid',
    'officePhone',
    'mobilePhone',
    'timezone',
    'state'
  ]

  initialize() {
    if(!franchiseeJson) console.warn('No franchiseeJson found')
    this.franchiseeJson = franchiseeJson
  }

  connect(){
    if(this.franchiseeJson)
      this._populateFranchiseeDropdown()
  }

  addUser(evt){
    evt.preventDefault()
  }

  changeFranchisee(evt){
    let fran = this.franchiseeJson.find(f => f.id == parseInt(evt.target.value))

    if(!evt.target.value) fran = this._blankFranchisee()

    this.nameTarget.value           = fran.name
    this.emailTarget.value          = fran.email
    this.usernameTarget.value       = fran.username
    this.franchiseeTypeTarget.value = fran.franchisee_type
    this.uidTarget.value            = fran.uid
    this.parentUidTarget.value      = fran.parent_uid
    this.officePhoneTarget.value    = fran.office_phone
    this.mobilePhoneTarget.value    = fran.mobile_phone
    this.timezoneTarget.value       = fran.timezone
    this.stateTarget.value          = fran.state
  }

  _blankFranchisee(){
    return {
      name: '',
      email: '',
      username: '',
      franchisee_type: '',
      uid: '',
      parent_uid: ''
    }
  }

  _populateFranchiseeDropdown(){
    this._createAppendSelectOption({id: '', name: 'Select Name'})
    this._createGroupedOptions()
  }

  _createAppendSelectOption(opts){
    var opt = document.createElement('option')
    opt.value = opts.id
    opt.text  = opts.name
    this.franchiseeDropdownTarget.append(opt)
  }

  _createGroupedOptions(){
    const data = this._groupAndSortedFranchisees

    this._orderedFranchiseeTypes.forEach(orderType => {
      let stateGroup = document.createElement('optgroup')
      stateGroup.label = orderType

      data[orderType].forEach((franchisee) => {
        var opt = document.createElement('option')
        opt.value = franchisee.id
        opt.text  = franchisee.name
        stateGroup.append(opt)
      })

      this.franchiseeDropdownTarget.append(stateGroup)
    })
  }

  get _groupAndSortedFranchisees(){
    if(this._cached_groupAndSortedFranchisees)
      return this._cached_groupAndSortedFranchisees

    this._cached_groupAndSortedFranchisees = this.franchiseeJson.sort((f1, f2) => {
      return f1.name?.localeCompare(f2.name)
    }).reduce((acc, item)=>{
      acc[item.franchisee_type] ||= []
      acc[item.franchisee_type].push(item)

      return acc
    }, {})

    return this._cached_groupAndSortedFranchisees
  }

  get _orderedFranchiseeTypes(){
    const orderRef = ['MASTER', 'STATE', 'AREA', 'PHOTO']

    return Object.keys(this._groupAndSortedFranchisees).sort((i1, i2) => {
      const p2 = orderRef.indexOf(i2)
      const p1 = orderRef.indexOf(i1)

      return (p1<0||p2<0) ? 1 : Math.sign(p1-p2)
    })
  }
}