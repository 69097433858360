import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'minContent', 'maxContent']

  showFullContent(evt) {
    evt && evt.preventDefault();
    this.contentTarget.classList.add('-maximise');
    this.maxContentTarget.classList.add('-hide');
    this.minContentTarget.classList.remove('-hide');
  }

  hideFullContent(evt) {
    evt && evt.preventDefault();
    this.contentTarget.classList.remove('-maximise');
    this.minContentTarget.classList.add('-hide');
    this.maxContentTarget.classList.remove('-hide');
  }

  initialize() {
    if(this.contentTarget.clientHeight < 100) {
      this.contentTarget.classList.remove('-maximise');
      this.minContentTarget.classList.add('-hide');
      this.maxContentTarget.classList.add('-hide');
    }
  }
}