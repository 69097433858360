import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = [ 'replaceField', 'preview' ]

  connect() { }

  replace(event) {
    event.preventDefault()
    event.stopPropagation()
    this.replaceFieldTarget.style.display = 'block'
  }

  cancelReplace(event) {
    event.preventDefault()
    this.replaceFieldTarget.style.display = 'none'
  }

  removeToggle(event) {
    this.previewTarget.classList.toggle('destroying', event.target.checked)
  }

}
