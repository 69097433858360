import { Controller } from 'stimulus';

// The checklist tasks used for marking as completed
export default class extends Controller {
  static targets = ['input'];

  connect(){
    this._setState()
    this._updateCssClasses()
    this._eventOnMutation()
    //this._setupSortable()

    // See app/views/client/jobs/_form_checklist.html.erb
    this._log(`Attached to ${this.element.tagName} element`)

    this.element.checklistController = this

    this._log('Connected!')
  }

  updateCheckTask(evt){
    const checkboxDetail = {
      target: evt.target,
      action: this._identifyCheckboxAction(evt.target)
    }

    if(this._callbackCheckIntercept(evt, checkboxDetail)) {
      this._processCheckboxClick(checkboxDetail)
    } else {
      this._stopChange(evt.target)
    }
  }

  // If the checkbox represents "image proofing" a popup is triggered. The checkbox
  // won't be marked completed. This is done through the image proofing UI.
  //
  _callbackCheckIntercept(evt, checkboxDetail){
    const codeTrigger = evt.target.dataset.codeTrigger

    if(codeTrigger == 'IMAGE_PROOFING' && this._toBeChecked(checkboxDetail)) {
      this.openImageProofingPopup()
      return false // Popup will take over
    } else if(codeTrigger == 'IMAGES_UPLOADED' && this._toBeChecked(checkboxDetail)){
      return true
    } else {
      return true
    }
  }

  // TODO: If required: _toBeUnchecked(checkboxDetail){}
  _toBeChecked(checkboxDetail){
    return checkboxDetail.action == 'CHANGE' && checkboxDetail.target.checked
  }

  _processCheckboxClick(checkboxDetail){
    if(checkboxDetail.action == 'CHANGE') {
      this._setState()
    } else {
      this._stopChange(checkboxDetail.target)
    }

    this._updateCssClasses()
  }

  _stopChange(checkboxEl){
    checkboxEl.checked = !checkboxEl.checked
  }

  // Trigger a submit only if there are checklist items to be submitted.
  // Image proofing will then use this when submitting corrections after
  // the user has selected checklist tasks up to the 'Image Proofing' task
  //
  submitChecklist(opts = {}){
    if(opts.markTaskIdComplete) {
      this._markCompletedAndSubmit(opts.markTaskIdComplete)
    } else {
      this.element.submit()
    }
  }

  openImageProofingPopup(){
    const el = document.querySelector("#popup-image-proofing")

    if(el) {
      this._resetProofingTurboFrame(el)
      el.popupController.open()
    } else {
      this._warn('No popup HTML present on page!')
    }
  }

  // At the moment if "-locked" CSS class is present no action is to happen
  // Modes are: CHANGE, CHANGE, NO_CHANGE, UNKNOWN (unacceptable)
  //
  _identifyCheckboxAction(target){
    let changeType = 'UNKNOWN'

    if(target.classList.contains('-locked')) {
      changeType = 'LOCKED'
      this._log(`Marking: ${target.checked}`)
    } else {
      if(this._state.nextTaskId == target.value) {
        this._log('state.nextTaskId == checklist ID... #setState called')
        changeType = 'CHANGE'
      } else if(this._state.recentTaskId == target.value && target.checked == false){
        this._log('state.recentTaskId == checklist ID...  #setState called')
        changeType = 'CHANGE'
      } else {
        changeType = 'NO_CHANGE'
        console.warn('Not the next item in the list', target, 'STATE:', this._state)
      }
    }

    return changeType
  }

  // Method to mark and submit checklist externally
  // Implemented for "Image Proofing"
  _markCompletedAndSubmit(checklistTaskId){
    if(!this._onForm()) throw 'Can not submit. Controller is not a FORM element'
    if(checklistTaskId != this._imageProofingTaskId) throw 'Image Proofing IDs do not match'

    const item = this.inputTargets.find( e => e.dataset.checklistItemId == checklistTaskId)

    if(item) {
      item.checked = true
      this.submitChecklist()
    } else {
      throw 'No "Image Proofing" checklist present!'
    }
  }

  /*
    Watch for the checklist change on JobType change from drop down menu
    This sets the UI restrictions on what can be selected (i.e. next checklist)
  */
  _eventOnMutation(){
    const config = {childList: true, subTree: true}

    const observer = new MutationObserver((_mutations)=>{
      this._setState()
      this._updateCssClasses()
    })

    observer.observe(this.element.querySelector('ul'), config)
  }

  // Keeps track of check list order. As the user checks of the items it enables
  // the next. If one is disabled it enables the previous
  //
  // "Image proof" button is enabled/disabled depending if it's next or not
  //
  _setState(){
    this._state = {
      recentTaskId: this._recentTaskElement()?.value,
      nextTaskId:   this._nextTaskElement()?.value
    }

    this._triggerEvent('checklist:afterStateUpdate', {detail: this._state})

    //this._toggleImageProofBtn()
    //this._toggleImageProofDownloadBtn()

    this._log(`Setting checklist state variable: ${JSON.stringify(this._state)}`)
  }

  _recentTaskElement(){
    const checked = this.inputTargets.filter(el => el.checked)
    const el      = checked[checked.length-1]

    return el ? el : null
  }

  _triggerEvent(evtName, opts = {}){
    const evt = new CustomEvent(evtName, opts)
    this._log('triggering event', {evtName, opts})
    this.element.dispatchEvent(evt)
  }

  _nextTaskElement(){
    const nextEl = this.inputTargets.filter(el => !el.checked)[0]
    return nextEl ? nextEl : null
  }

  // Identify what's not checked and mark disabled if necessary
  _updateCssClasses(){
    const ary =  this.inputTargets.filter(el => el.checked == false)

    if(ary[0]) {
      ary[0].closest('label.checkbox').classList.remove('-disabled')
      ary.splice(1).forEach(el => el.closest('label.checkbox').classList.add('-disabled'))
    }
  }

  _setupSortable(){
    document.body.classList.contains('development') && console.info('TODO: checklist for job tasks')
  }

  // NOTE: Not used
  // _toggleImageProofBtn(){
  //   const el = document.querySelector('#job-panel-proof-images-btn')

  //   // Image Proofing is not enabled for this JobType or it's been approved
  //   if(!el) return

  //   // If completed the popup will only allow showing and not approving/correcting
  //   if(el.dataset?.permanentlyEnabled=='true') return

  //   const nextItemId  = this._nextTaskElement()?.dataset.checklistItemId
  //   const btnDisabled = nextItemId != this._imageProofingTaskId

  //   el.toggleAttribute('disabled', btnDisabled)
  // }

  // NOTE: Not used
  // _toggleImageProofDownloadBtn(){
  //   if(!this._enableImageProofDownload()) return

  //   const el = document.querySelector('#job-panel-proof-images-download-btn')
  //   if(!el) return

  //   el.classList.remove('disabled')
  //   el.querySelector('input[type="checkbox"]').removeAttribute('disabled')
  // }

  _enableImageProofDownload(){
    const code = this._recentTaskElement()?.dataset.codeTrigger
    return code == 'IMAGES_UPLOADED' || code == 'IMAGE_PROOFING'
  }

  get _imageProofingTaskId(){
    return this.element.querySelector('[data-image-proofing-task-id]')?.dataset.imageProofingTaskId
  }

  _onForm(){
    return this.element.tagName == 'FORM'
  }

  // This gets around the popup "caching". Turbo doesn't seem to have a way
  // to clear out the HTML so it requests the page again.
  // If the 'complete' attribute is removed it triggers another HTTP request
  //
  // Could trigger this if necessary but for now it's every time the checkbox
  // is clicked to open the 'Image Proofing' popup
  //
  _resetProofingTurboFrame(el){
    const tFrame = el.getElementsByTagName('turbo-frame')[0]
    tFrame?.removeAttribute('complete')
  }

  _log()  { console.debug('[D][JobChecklistController]', ...arguments) }
  _warn() { console.warn('[W][JobChecklistController]', ...arguments) }
}