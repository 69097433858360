import { Controller } from 'stimulus';
import Swiper, {Navigation, Scrollbar, Thumbs, Keyboard} from 'swiper';

import 'swiper/swiper-bundle.min.css';

// This controller is coupled with JobChecklist controller
export default class extends Controller {
  static targets = ['thumb']

  connect(){
    this._log('Setting up Swiper!')

    // Thumbs swiper needs initializing first and pasted to the main swiper
    this._swiperThumbs = this._initThumbsSwiper()
    this._swiperMain   = this._initMainSwiper()

    this._createImageProofPath = this.element.dataset.createImageProofPath

    this._checklistController = document.querySelector('[data-controller="job-checklist"]').checklistController
    if(!this._checklistController)
      throw 'No checklist controller linked with image proofing controller'

    this._checklistTaskId = this.element.dataset.checklistTaskId
    if(!this._checklistTaskId)
      throw 'No checklist task ID present'

    this._log(`Checklist controller found. Check list ID for image proofing: ${this._checklistTaskId}`)

    // TODO: May want to disable if there are corrections pending until
    // the user adds more corrections so we don't get double up emails
    this._proofingRequired(this._hasCorrections())

    this.imageProofingController = this
  }

  correctionRequired(evt){
    this._toggleThumbBoxCorrection(evt.target.checked, evt.target)
    this._enableNotesForSlide(evt.target.checked, evt.target.value)
    this._proofingRequired(this._hasCorrections())
  }

  cancelImageProofing(_evt){
    this._closePopup()
  }

  // No task completion trigger
  submitImageCorrections(evt){
    Rails.disableElement(evt.target)

    this._submitImageProofing(this._collectImageProofData()).then(_data => {
      this._checklistController.submitChecklist()
      //location.reload()
    })
  }

  // Completion trigger
  // JobChecklist controller will trigger "location.reload()"
  submitApproveAll(evt){
    if(confirm("Mark the 'Proof images' checklist task completed?")) {
      Rails.disableElement(evt.target)

      this._completeChecklistTask()
      this._disableButton()
      this._closePopup()
    }
  }

  _initMainSwiper(){
    if(!this._swiperThumbs) throw 'Thumbs swiper needs creating first!'

    const opts =  { // If we need pagination
      modules: [Navigation, Scrollbar, Thumbs, Keyboard],
      thumbs:  {swiper: this._swiperThumbs},
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      // And if we need scrollbar
      scrollbar: {
        el: document.querySelector('#swiper-main-scrollbar'), //'.swiper-scrollbar',
      },
      threshold: 5,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      }
    }

    return new Swiper(this.element.querySelector('.swiper-main'), opts)
  }

  _initThumbsSwiper(){
    //if(!this._swiperMain) throw 'Main swiper needs creating first!'

    return new Swiper(this.element.querySelector('.swiper-thumbs'), {
      controller: {
        control: this._swiperMain,
        inverse: true
      },
      threshold: 5,
      slidesPerView: 5,
      spaceBetween: 10,
      // onAny(eventName, ...args) {
      //   console.log('Event: ', eventName);
      //   console.log('Event data: ', args);
      // }
    })
  }

  _disableButton(){
    document.querySelector('#job-panel-proof-images-btn')?.setAttribute('disabled', '')
  }

  // Using PopupController
  _closePopup(){
    this.element.closest('.page-popup')?.popupController.close()
  }

  _proofingRequired(enabled){
    //this.element.querySelector('#img-proof-submit-approval')?.classList.toggle('-hidden', enabled)
    this.element.querySelector('#img-proof-submit-correction')?.classList.toggle('-hidden', !enabled)
  }

  // Could also use #_collectImageProofData to check. Logic is in two places
  _hasCorrections(){
    return !!this.thumbTargets.filter(e => e.checked).length
  }

  _enableNotesForSlide(enabled, idx) {
    const el = document.querySelector(`[data-main-slide-idx="${idx}"]`)
    el.classList.toggle('-corrections', enabled)
  }

  // Using "PrintQuality Display ID" as reference
  _collectImageProofData(){
    let correctionsInfo = []

    document.querySelectorAll('.-corrections[data-main-slide-idx]').forEach(el => {
      correctionsInfo.push({
        pq_display_id: el.dataset.pqImageId,
        description:   el.querySelector('.proof-options > textarea')?.value
      })
    })

    return correctionsInfo
  }

  _toggleThumbBoxCorrection(enabled, inputElement){
    const el = inputElement.closest('.swiper-slide')
    el.classList.toggle('-correction', enabled)
  }

  // Expecting the task to be the next to be completed. It will
  // submit the form and therefore render a new page showing the notes
  _completeChecklistTask(){
    this._log('Mark the checklist "Image Proofing" item completed!')

    this._checklistController.submitChecklist({markTaskIdComplete: this._checklistTaskId})
  }

  // Submit "Image Proofing" by AJAX then submit
  //
  // Image proofing corrections created are also emailed out to
  // User(s)/ClientUser(s) associated with the job
  //
  _submitImageProofing(imageProofs){
    if(!this._createImageProofPath) throw 'No URL to create notes present!'

    this._log('Submitting image proofing content', imageProofs)

    const promise = fetch(this._createImageProofPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        image_proofs: imageProofs
      })
    }).then(resp => {
      if(resp.status == 500) this._alert500()
      return resp.json()
    }).then(data => {
      this._log('Image Proofing submitted')
      return data
    }).catch(err => this._warn('Could not create Image Proofing!', err))

    return promise
  }
  _alert500(){
    alert('Server Error: Tech team will be notified')
  }
  _log()  { console.debug('[D][ImageProofingController]', ...arguments) }
  _warn() { console.warn('[W][ImageProofingController]', ...arguments) }
}