// REF: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/

import { Controller } from 'stimulus';
import EmlFormat from 'eml-format';
import textVersion from 'textversionjs'

export default class extends Controller {
  static targets = [
    'dropArea',
    'subjectField',
    'bodyField',
    'fromField',
    'toField',
    'sentAtField',
    'comTypeField'
  ]

  initialize(){
    this._preventDefaults()
    this._eventsOnDragOver()
    this._eventOnDrop()
  }

  _preventDefaults(){
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.dropAreaTarget.addEventListener(eventName, evt => {
        evt.preventDefault()
        evt.stopPropagation()
      }, false)
    })
  }

  _eventsOnDragOver(){
    ['dragenter', 'dragover'].forEach(eventName => {
      this.dropAreaTarget.addEventListener(eventName, evt => {
        this.element.classList.add('-dragged-over')
      }, false)
    });

    ['dragleave', 'drop'].forEach(eventName => {
      this.dropAreaTarget.addEventListener(eventName, evt => {
        this.element.classList.remove('-dragged-over')
      }, false)
    });
  }

  _eventOnDrop(){
    this.dropAreaTarget.addEventListener('drop', evt => {

      const files  = evt.dataTransfer.files
      const reader = new FileReader()

      reader.onload = evt => {
        this._parseEmail(evt.target.result)
      }

      reader.readAsText(files[0], 'UTF-8')
    })
  }

  // HTML will be striped of styles
  // Only Html/Plain get through, no images
  _parseEmail(eml){
    EmlFormat.parse(eml, (error, data) => {
      if (error) return console.warn('Error in parsing:', error);

      this._updateSelectField(data.headers['Subject'])
      this.toFieldTarget.tagify.addTags(data.headers['To'])
      this.toFieldTarget.tagify.addTags(data.headers['Cc'])
      this._addToFromSelect(data.headers['From'])

      this.comTypeFieldTarget.value = 'EMAIL_RETRO_UPLOAD'

      this._parseEmailBody(data.body)
    })
  }

  // Recursive on multipart/alternative
  _parseEmailBody(body){
    if(typeof(body) == 'object' && 'forEach' in body) {
      body.forEach(item => {
        const cType = item.part.headers['Content-Type']

        if(cType.match(/text\/(html|plain)/)) {
          this._updateBody(this._htmlToString(item.part.body))
        } else if(cType.match(/multipart\/alternative/)){
          console.info('Matched alternative!')
          this._parseEmailBody(item.part.body)
        } else {
          console.warn('Unknown Content-Type', cType)
        }
      })
    } else {
      this._updateBody(body)
    }
  }

  _updateBody(val){
    this.bodyFieldTargets.forEach(el => {
      el.value = val
    })
  }

  _updateSelectField(val){
    this.subjectFieldTargets.forEach(el => {
      el.value = val
    })
  }

  _addToFromSelect(address) {
    const sel = document.querySelector('[name="communication[from]"]')
    let el    = document.createElement('option')
    el.classList.add('retro-email-upload')
    el.text  = `From dragged email (${address})`
    el.value = address
    sel.add(el)
    sel.value = address

    this._triggerOn(sel, 'change')
  }

  // Some elements sync with another hidden element on changes
  // TODO: DEPRECATED: Convert the event code over. https://developer.mozilla.org/en-US/docs/Web/Events/Creating_and_triggering_events
  _triggerOn(el, evtName) {
    var event = document.createEvent('HTMLEvents');
    event.initEvent(evtName, true, false);
    el.dispatchEvent(event);
  }

  // Convert to a markdown style, avoid HTML tags
  _htmlToString(html){
    return textVersion(html)
  }
}