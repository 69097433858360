/*
  SimpleForm custom input located at app/inputs/o2v_textarea_placeholder_input.rb
  expects the  +placeholderJSON+ variable to be defined on the page somewhere

  communication_form_controller has code that does the task of 'interactive
  placeholders' The code here is newer but decided to not convert it over as it
  already works. The code in that controller does more than just placeholders
  while this is specific to placeholders
*/
import { Controller } from 'stimulus';
import { Liquid } from 'liquidjs';

// https://github.com/open2view/tribute from https://github.com/zurb/tribute (used v5.1.3)
import Tribute from 'tributejs';
require('tributejs/tribute.css')

export default class extends Controller {
  liquidEngine = new Liquid()

  static targets = ['textarea', 'hiddenField']

  connect() {
    if(!placeholderJSON){
      this._wrn('No placeholders found, no placeholderJSON defined on page')
    } else {
      this._setupPlaceholderDropdown()
    }
  }

  startEditing(_evt) {
    this.textareaTarget.value = this.hiddenFieldTarget.value
  }

  stopEditing(_evt) {
    this.hiddenFieldTarget.value = this.textareaTarget.value
    this.textareaToData()
  }

  textareaToData() {
    try {
      this.textareaTarget.value = this._render(this.textareaTarget.value)
    } catch (error) {
      this._wrn('ERROR', error)
    }
  }

  _render(templateString){
    if (!templateString) return ''
    return this.liquidEngine.parseAndRenderSync(templateString, placeholderJSON)
  }

  _setupPlaceholderDropdown(){
    const tribute = new Tribute({
      values: this._valuesFromPlaceholderJSON(),
      trigger: '{{',
      selectTemplate: (item) => { return `{{${item.original.value}}}` }
    })

    this.textareaTarget.addEventListener('tribute-active-true', ()=>{
      this._tributeOpen = true
    })

    this.textareaTarget.addEventListener('tribute-active-false', ()=>{
      this._tributeOpen = false
    })

    tribute.attach(this.textareaTarget)

    this.textareaToData()
  }

  _valuesFromPlaceholderJSON(){
    let ary = []

    Object.keys(placeholderJSON).forEach(key =>
      ary.push({key: `${key} (${placeholderJSON[key]})`, value: key})
    )

    return ary
  }

  _wrn(){ console.warn('[W][TextareaPlaceholder]', ...arguments) }
}