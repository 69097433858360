import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['canvas']
  static values = { key: String, url: String, filename: String }

  static adobeReady = false;

  // XXX: TODO - We should probably catch when the pop-up is closed
  // and tear down the Adobe viewer
  connect() {
    if(!this.adobeReady) {
      // Don't really need to remove this event listener, this should only fire once
      document.addEventListener("adobe_dc_view_sdk.ready", this.onAdobeReady.bind(this));

      let tag = this.adobeScriptTag = document.createElement('script')
      tag.src = "https://documentservices.adobe.com/view-sdk/viewer.js"
      document.head.appendChild(tag)
    } else {
      this.onAdobeReady()
    }
  }

  onAdobeReady() {
    this.adobeReady = true
  
    const adobeDCView = new AdobeDC.View({clientId: this.keyValue, divId: this.element.id });

    adobeDCView.previewFile({
      content: { 
        location: { url: this.urlValue }
      },
      metaData: { fileName: this.filenameValue }
    },
    {
      embedMode: "SIZED_CONTAINER",
      showLeftHandPanel: true,
      dockPageControls: true,
      showPageControls: true,
    });
  }


}

