/*
  NOTE: user_interface_list_service files related uses this name
  README: user_interface_list_service.rb
*/

/*
 .o2v-list-frame class can be used if only one

 'data-enable-column-expanding' on main element will add a class to the TH
 element to expand the columns

 'data-enable-selectable' Allow the user to select rows
*/
O2vUserInterfaceListService = function(options){
  let mainEl = options.el

  //let elSel     = mainElSel //'.o2v-list-frame'
  if(typeof(mainEl) == 'string')
    mainEl = document.querySelector(mainEl)

  if(mainEl.hasAttribute('data-enable-column-expanding')) {
    options.autoExpand = true
  }

  if(mainEl.hasAttribute('data-enable-selectable')) {
    options.selectable = true
  }

  let curColHov = null
  let _mouseOverTimer = null

  const _getSelected = function(){
    return _selEl('tr[data-record-id].-selected')
  }

  const _debug = function(msg){
    console.log('[O2vUserInterfaceListService]', msg)
  }

  const _unselectRows = function(){
    _eachSel('.o2v-list-table tr', function(el){
      el.classList.remove('-selected')
    })
  }

  const _selEl = function(sel){
    return sel ? mainEl.querySelector(sel) : mainEl
  }

  const _selAll = function(sel){
    return mainEl.querySelectorAll(sel)
  }

  const _eachSel = function(sel, func){
    mainEl.querySelectorAll(sel).forEach(function(el){
      func(el)
    })
  }

  const _eventsOnRow = function(){
    _eachSel('.o2v-list-table tr', function(el){
      el.addEventListener('click', function(_evt){
        _unselectRows()
        const rowEl = el.closest('tr')

        _debug('Selected record:'+rowEl.getAttribute('data-record-id'))
        rowEl.classList.add('-selected')
        _enableButtons()
      }.bind(this))
    }.bind(this))
  }

  const _enableButtons = function(_recordJson){
    _debug('Events for Disable/Enable buttons')

    _eachSel('.o2v-list-buttons [data-disableable]', function(btn){
      _debug('Disable/Enable button: '+btn.innerText)
      btn.removeAttribute('disabled')
    }.bind(this))
  }

  const _eventsOnButtons = function(){
    _debug('Events for buttons auctions')

    _eachSel('.o2v-list-buttons .btn', function(el){
      el.addEventListener('click', function(_evt){
        if(!el.hasAttribute('disabled') && _allow(el)){
          _debug('Click event fired for button: '+el.innerText)
          _actionOnButton(el)
        }
      }.bind(this))
    }.bind(this))
  }

  const _eventOnMouseOver = function(){
    _eachSel('.o2v-list-row td', function(el){
      el.addEventListener('mouseover', function(){
        clearTimeout(_mouseOverTimer)

        _mouseOverTimer = setTimeout(function(){
          _mouseOverFunc(el)
        }.bind(this), 200)
      }.bind(this))
    }.bind(this))

    mainEl.addEventListener('mouseleave', function(evt){
      _eachSel('th.-td-hovering', function(otherEl){
        otherEl.classList.remove('-td-hovering')
      })
    }.bind(this))
  }

  const _mouseOverFunc = function(el){
    const ary = el.className.split(' ')
    const col = ary.filter(function(str){ return str.match(/^col\-/) })[0]

    if(curColHov != col) {
      _eachSel('th.-td-hovering', function(otherEl){
        otherEl.classList.remove('-td-hovering')
      })

      _selEl('th.'+col).classList.add('-td-hovering')
      curColHov = col
    }
  }

  const _allow = function(btn){
    var str = btn.getAttribute('data-o2v-confirm')

    if(str){
      return confirm(str)
    } else {
      return true
    }
  }

  const _actionOnButton = function(el){
    const url    = el.getAttribute('data-url')
    const method = el.getAttribute('data-method')

    if(url){
      const recordId = _getSelected().getAttribute('data-record-id')
      const newUrl = url.replace(':record_id', recordId)

      _debug('Redirecting to URL: '+newUrl)

      if(method) {
        _submitForm(newUrl, method)
      } else {
        window.location.href = newUrl
      }
    }
  }

  // Using the FORM generated by Rails, they uses a custom "_method"
  const _submitForm = function(url, method){
    _debug('Submitting form using HTTP method: '+method)
    const form = _selEl('form.o2v-list-form')
    form.setAttribute('action', url)
    form.querySelector('[name=_method]').value = method
    form.submit()
  }

  this.initPage = function(){
    _debug('Initializing')
    if(options.selectable) _eventsOnRow()
    _eventsOnButtons()
    if(options.autoExpand) _eventOnMouseOver()
    _selEl().setAttribute('data-state', 'init')
  }

  return this
}

// TODO: Auto or Manual initialization?
// document.addEventListener('DOMContentLoaded', function(){
//   o2vUserInterfaceListService.initPage()
// })
