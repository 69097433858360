import flatpickr from 'flatpickr';

require('flatpickr/dist/themes/dark.css')

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'btnClear']

  connect() {
    this.flatpickr = flatpickr(this.inputTarget, {
      enableTime: this.usingTime(),
      dateFormat: 'Y-m-d H:i',
      altInput: true,
    });

    if(!this.inputTarget.value.length) this._disabled(true)

    this._checkTimezone()
  }

  disconnect(){
    this.flatpickr.destroy()
  }

  didChange(_evt){
    if(this.inputTarget.value.length) this._disabled(false)
  }

  clear(evt){
    evt.preventDefault()

    if(!evt.target.classList.contains('-disabled')) {
      this.flatpickr.clear()
      this._disabled(true)
    }
  }

  _disabled(toggle) {
    if(toggle)
      this.btnClearTarget.classList.add('-disabled')
    else
      this.btnClearTarget.classList.remove('-disabled')
  }

  _checkTimezone() {
    try {
      const inputTz = this.inputTarget.getAttribute('timezone')
      const browserTs = Intl.DateTimeFormat().resolvedOptions().timeZone

      if (inputTz && inputTz != browserTs) {
        console.warn(`The two timezones do not much for ${this.inputTarget.name}`, {inputTz, browserTs})
      }
    } catch {
      console.error('Failed to check timezone')
    }


  }

  usingTime() {
    let val = this.inputTarget.dataset.flatpickrTime
    return (val ? val == 'true' : false)
  }
}