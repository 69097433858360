import { Controller } from 'stimulus';
import {highlightHint} from '../utils/html_helpers.js'

/*
  Handles multiple fields

  Dynamically update the checklist on job type selection. The checklist
  is only editable on creation or the user is a admin

  Updates the tag white list from JobType for autocompletion

  Set specific user based on state. The timezone is set using this state field.
  There needs to be user IDs in the state option and an associated ID in the
  delegator select for this to work.

  Keeps checklist state on change

  The 'data-controller' has to wrap the job type drop down checklist UI

  The JSON (jobTypeJson) needs to be present on the page. This happens in
  the partial (client/jobs/_form_checklist.html.erb)
*/
export default class extends Controller {
  // Reference by checklist_item_id => checklist_job_id
  // Mark as complete
  savedSelected = {}
  newRecord

  static targets = [
    'selectJobType',
    'checklistJson',
    'checklistArea',
    'checklistItemTemplate',
    'jobTags',
    'checklistItem'
  ]

  connect(){
    this.newRecord =  this.element.dataset.newRecord == 'true'

    if (!this.hasJobTypeTarget) return
    this._saveSelected()
    this._initOnNew()
    this._toggleChecklist()
  }

  stateSelect(evt){
    this.#markDirty(evt.target)
    const selEl = this.#getSelectOption(evt.target)

    if(!this.#manuallyChanged(this.#timezoneElement)) {
      console.info('selEl.dataset.timezone', selEl.dataset)
      this.#updateTimezone(selEl.dataset.timezone)
    }

    // Only update if the job is NEW and NOT DIRTY
    if(this.newRecord && !this.#manuallyChanged(this.#jobDelegatorIdElement)) {
      this.#updateDelegatorId(selEl.dataset.delegatorId)
    }
  }

  // Disabled for now. State will choose delegator and timezone
  selectDelegator(evt){
    // this.#markDirty(evt.target)
    // const selEl = this.#getSelectOption(evt.target)

    // this.#updateTimezone(selEl.dataset.timezone)
  }

  // Not used
  #setTimezoneByDelegator(){
    const timezone = this.#getSelectOption(this.#jobDelegatorIdElement)?.dataset.timezone
    if(timezone) this.#updateTimezone(timezone)
  }

  updateChecklist(evt){
    let jobType = this._findJobType(parseInt(evt.target.value))
    this._clearChecklist()
    this._addCheckList(jobType)
    this._updateTagifyWhitelist()
  }

  /* No checklist exists on new page, get the job type and generate from JSON */
  _initOnNew(){
    let jobType = this._findJobType(parseInt(this.selectJobTypeTarget.value))

    if(jobType.checklist.length && this._checklistEmpty()) {
      this._addCheckList(jobType)
    }
  }

  // Get the tagify element and update it's whitelist
  _updateTagifyWhitelist(){
    if(!this.hasJobTypeTarget) return

    let jobType = this._findJobType(parseInt(this.selectJobTypeTarget.value))
    this.jobTagsTarget.tagify.settings.whitelist = jobType.tags
  }

  _findJobType(jobTypeId){
    return jobTypeJson.filter((item)=>{ return item.job_type_id == jobTypeId })[0]
  }

  _addCheckList(checklistAry){
    if(checklistAry.checklist.length) {
      checklistAry.checklist.forEach((item)=>{
        this._appendChild(this._checklistFragment(item))
      })
    }

    this._toggleChecklist()
  }

  _toggleChecklist(){

    const jobType = this._findJobType(parseInt(this.selectJobTypeTarget.value))

    if(jobType.checklist.length)
      this.element.classList.remove('-no-checklist')
    else
      this.element.classList.add('-no-checklist')
  }

  // Create from template generated by SimpleForm (O2vCheckBoxesInput)
  _checklistFragment(obj){
    const idx    = this._itemCount()
    const objId = obj.id || `_new_${idx}`
    let str      = this.checklistItemTemplateTarget.innerHTML.trim()
    let el       = document.createElement('li')

    el.innerHTML = str.replace(/%IDX%/g, idx)

    const elId = `job_checklist_id_${objId}`

    let checkboxEl = el.querySelector('label.checkbox input')

    checkboxEl.setAttribute('id', elId)
    checkboxEl.setAttribute('value', objId)

    this._updateRecordEl(el, checkboxEl, objId)

    el.querySelector('label.checkbox').setAttribute('for', elId)

    el.querySelector('.title').innerText = obj.title

    el.querySelector('input.checklist_item_id').value = objId

    return el
  }

  /* Update or remove the element using the saved state.
  /  The saved state keeps track of the checklist item ID and the
  /  checklist item job ID (Join table). the check state is then true|false
  */
  _updateRecordEl(el, checkboxEl, objId){
    let inputIdEl = el.querySelector('.checklist_job_id')

    if(objId in this.savedSelected) {
      const {jobId, checked} = this.savedSelected[objId]
      if(checked) checkboxEl.setAttribute('checked', true)
      inputIdEl.value = jobId
    } else {
      inputIdEl.remove()
    }
  }

  /* Save the selected state for later. Change the state
  /  if user reselects job type
  */
  _saveSelected(){
    this.checklistItemTargets.forEach((input)=>{
      const jobId  = parseInt(input.getAttribute('data-checklist-item-job-id'))
      const itemId = parseInt(input.getAttribute('data-checklist-item-id'))
      this.savedSelected[itemId] = {jobId: jobId, checked: input.checked}
    })
  }

  _appendChild(child){
    this._checklistRoot().appendChild(child)
  }

  _checklistRoot(){
    return this.checklistAreaTarget.querySelector('ul')
  }

  _itemCount(){
    const elements = this.checklistAreaTarget.querySelectorAll('ul > li')
    return elements ? elements.length : 0
  }

  _clearChecklist(){
    this._checklistRoot().innerHTML = ''
  }

  _checklistEmpty(){
    return !this.element.querySelectorAll('ul li').length
  }

  #getSelectOption(el){
    return el.options[el.selectedIndex]
  }

  #updateTimezone(tz){
    if(!tz) return
    this.#updateField('timezone', tz)
  }

  #updateDelegatorId(dId){
    if(!dId) return
    dId = dId.toString()

    if(this.#currentDelegatorIds.includes(dId)) {
      this.#updateField('delegator_user_id', dId)
    } else {
      this.#warn(`No delegator ID ${dId} present in current list`)
    }
  }

  #updateField(name, value){
    const el =  this.#getElementByCol(name)

    if(!el) {
      this.#warn(`No element for ${name} for updating, ignored`)
      return
    }

    el.value = value
    highlightHint(el)
  }

  get #timezoneElement(){
    return  this.#getElementByCol('timezone')
  }

  get #jobDelegatorIdElement(){
    return  this.#getElementByCol('delegator_user_id')
  }

  #getElementByCol(name){
    const elements = document.getElementsByName(`job[${name}]`)

    if(elements.length != 1)
      this.#warn(`Wrong number of elements returned for ${name} ID`)

    return elements[0]
  }

  get #currentDelegatorIds(){
    const elements = Array.from(this.#jobDelegatorIdElement.options)
    return elements.map(el => el.value).filter(str => str != '')
  }

  // Avoid updating fields the user as set themselves. #manuallyChanged is
  // the accompanying method
  #markDirty(el){
    if(el) el.dataset.dirty = true
  }

  // or could be named #isDirty
  #manuallyChanged(el){
    return el && !!el.dataset.dirty
  }

  #log()  { console.debug('[D][JobFormController]', ...arguments) }
  #warn() { console.warn('[W][JobFormController]', ...arguments) }
}