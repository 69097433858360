import { Controller } from 'stimulus'

export default class extends Controller {
  mainColumn    = 'main_contact'
  billingColumn = 'billing_contact'
  fieldTypes    = 'input:not([type=hidden]), textarea:not([type=hidden])'

  static targets = [ 'contactMain', 'contactBilling' ]

  copy(evt) {
    evt.preventDefault()

    this.contactMainTarget.querySelectorAll(this.fieldTypes).forEach(function(el){
      const attrName = el.getAttribute('name')
      const sel     = attrName.replace(this.mainColumn, this.billingColumn)
      const nameSel = '[name="'+sel+'"]'

      this.contactBillingTarget.querySelector(nameSel).value = el.value
    }.bind(this))
  }
}