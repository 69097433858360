import { Controller } from 'stimulus';

export default class extends Controller {
  NOTICE  = 'notice'
  WARNING = 'warning'
  ERROR   = 'error'

  // msgTypes = ['notice', 'warning', 'error']
  static targets = [
    'wrapper',
    'templateWrapper',
    'templateMessage'
  ]

  initialize(){
    this.element.controller = this
  }

  // connect(){
  // }

  addNotice(msg){
    this.add(this.NOTICE, msg)
  }

  addWarning(msg){
    this.add(this.WARNING, msg)
  }

  addError(msg){
    this.add(this.ERROR, msg)
  }

  reset(){
    let elements = this.element.querySelectorAll('.messages')

    if(elements) elements.forEach(el => {
      el.remove()
    })
  }

  has(msgType){
    return !!this.wrapperFor(msgType)
  }

  add(msgType, msg) {
    if(!this.has(msgType)) this.makeWrapper(msgType)

    this.wrapperFor(msgType).querySelector('ul').append(this.makeMsg(msg))
  }

  wrapperFor(msgType) {
    return this.element.querySelector(`.messages.${msgType}`)
  }

  makeWrapper(msgType) {
    const str = this.templateWrapperTarget.innerHTML.replace('%TYPE%', msgType)
    let div = document.createElement('div')
    div.innerHTML = str
    this.element.append(div.children[0])
  }

  makeMsg(msg) {
    const str = this.templateMessageTarget.innerHTML.replace('%MESSAGE%', msg)
    let div = document.createElement('div')
    div.innerHTML = str
    return div.children[0]
  }
}