import { Controller } from 'stimulus';
import Sortable from 'sortablejs';

/* JS to create new elements for the checklist items */

// The checklist job's will use to create their checklist tasks
// The job_checklist_controller handles that logic
export default class extends Controller {
  createFlag = 'data-allow-creation'

  static targets = ['list', 'template', 'imageProofing']

  connect(){
    this._setupSortable()
    this.imageProofingChange()
  }

  markForRemoval(evt){
    const liEl = evt.target.closest('li')

    if(evt.target.checked)
      liEl.classList.add('-destroying')
    else
      liEl.classList.remove('-destroying')
  }

  newElementOnEnter(evt){
    if(evt.code == 'Enter' || evt.code == 'NumpadEnter') {
      evt.preventDefault();

      if(evt.target.hasAttribute(this.createFlag)) {
        this._addElement()
        evt.target.removeAttribute(this.createFlag)
      }
    }
  }

  newElement(evt) {
    evt.preventDefault();
    this._addElement()
  }

  imageProofingChange(_evt) {
    if(!this.imageProofingTarget) return

    if(this.imageProofingTarget.checked) {
      this._addPseudoElement({
        label:     this.imageProofingTarget.dataset.checklistLabelReady,
        elementId: 'checklist-image-proofing-uploaded',
        operator:  'USER_PROVIDER'
      })

      this._addPseudoElement({
        label:     this.imageProofingTarget.dataset.checklistLabelCompleted,
        elementId: 'checklist-image-proofing-completed',
        operator:  'CLIENT_USER'
      })
    } else {
      this.element.querySelector('#checklist-image-proofing-uploaded')?.remove()
      this.element.querySelector('#checklist-image-proofing-completed')?.remove()
    }
  }

  // These elements are not:
  // 1) Used to create checklist items
  // 2) Orderable
  // 3) Enabled for changes
  //
  _addPseudoElement(opts){
    const newEL = this._getTemplate()
    newEL.classList.add('pseudo-checklist', 'orderable-disabled')
    newEL.id = opts.elementId

    newEL.querySelectorAll('input, select').forEach(el => el.disabled = true)

    newEL.querySelector('input[type="text"]').value = opts.label
    newEL.querySelector('select.checklist-operator').value = opts.operator

    this.listTarget.appendChild(newEL);
  }

  // Insert at the end but before any pseudo-checklist elements
  _addElement(){
    const newEL = this._getTemplate()

    const list = this.listTarget.querySelectorAll('li:not(.pseudo-checklist)')
    list[list.length - 1].after(newEL)

    this.listTarget.querySelectorAll('orderable')
    newEL.classList.add('new-record')
    this._getFocus(newEL)
  }

  _getFocus(liEl){
    liEl.getElementsByTagName('input')[0].focus()
  }

  _getTemplate(){
    const time  = new Date().getTime()
    var liEl    = document.createElement('li')
    liEl.innerHTML = this.templateTarget.innerHTML.replace(/FIELD_INDEX/g, time)

    return liEl
  }

  _lastListItem(){
    return this.listTarget.lastElementChild
  }

  // Updates the checklist item sequence
  _setupSortable(){
    this.sortable = new Sortable(this.listTarget, {
      draggable: '.orderable',
      onEnd: _ =>{
        const inputSeq = [...this._sequenceElements()]

        inputSeq.forEach((input, idx) => {
          input.value = idx
        })
      }
    })
  }

  _sequenceElements(){
    return this.listTarget.getElementsByClassName('checklist-sequence')
  }
}
