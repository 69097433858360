import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  paginationPage = 1

  static targets = [
    'content'
  ]

  initialize() {
    const options = {root: document, threshold: 0.25}

    // The #connect method does more initialization
    this.intersectionObserver = new IntersectionObserver((entries) => {
      this.processIntersectionEntries(entries)
    }, options)
  }

  connect() {
    if(this.observeElement()) this.startObserving()
    // Expose to allow adding a message when creating a new communication
    this.element.controller = this
  }

  disconnect() {
    if(this.observeElement()) this.intersectionObserver.unobserve(this.observeElement())
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      if(entry.isIntersecting) this.loadMore()
    })
  }

  toggleClass(enabled) {
    let classList = this.element.classList
    enabled ? classList.add('-loading') : classList.remove('-loading')
  }

  loadMore() {
    if(!this._endOfCommunications()) {
      this.toggleClass(true)
      this.intersectionObserver.unobserve(this.observeElement())
      this.fetchCommunications()
    }
  }

  fetchCommunications(){
    const path = this.element.getAttribute('data-communications-url')

    this.paginationPage += 1

    Rails.ajax({
      url: path,
      data: `page=${this.paginationPage}`,
      type: 'GET',
      error: (_response, _message, xhr)=>{
        if(xhr.status == 401)
          alert('Login session may have expired, login required')
        else
          alert('Error: Failed to get communications')
      },
      success: (response)=>{
        this.appendResponse(response)
      },
      complete: _=>{
        this.toggleClass(false)
      }
    })
  }

  appendResponse(response){
    const elems = response.querySelector('body').children

    if(elems.length) {
      while(elems.length) {
        this.contentTarget.appendChild(elems[0])
      }

      this.startObserving()
    } else {
      const endHeader = document.createElement('section')
      endHeader.classList.add('end-of-communications')
      this.contentTarget.appendChild(endHeader)
    }
  }

  // Fires when the last element is scrolled into view
  //
  // Once ajax has finished and the next elements are loaded run again
  // to attach the next trigger
  startObserving(){
    this.intersectionObserver.observe(this.observeElement())
  }

  observeElement(){
    return this.contentTarget.querySelector(':scope > article.message-row:last-child')
  }

  /* DISABLED Ajax timeline requests, showing all coms and events now */
  _endOfCommunications(){
    return true
    //return !!this.element.getElementsByClassName('end-of-communications').length
  }
}