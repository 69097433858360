import { Controller } from 'stimulus'

import Tagify from '@yaireo/tagify'
require('@yaireo/tagify/src/tagify.scss');

/*
  Wrapper for Tagify
  Read the our own SimpleForm input class for more info (O2vTagifyInput)
*/
export default class extends Controller {
  static targets = ['input', 'template']

  connect(){
    this._setupTagify()

    this.tagify.on('add', this._addInputEl.bind(this))
    this.tagify.on('remove', this._removeInputEl.bind(this))

    this.element.stimulusController = this
  }

  _setupTagify(){
    this.tagify = new Tagify(this.inputTarget, {
      whitelist: this._whitelist(),
      placeholder: 'Type and press enter to add multiple entries',
      dropdown: {enabled: 0, closeOnSelect: false}
    })

    this.element.tagify = this.tagify
  }

  disconnect() {
    this.tagify.destroy()
  }

  clearValues() {
    this.tagify.removeAllTags()
    this.element.querySelector('.tagify-current input').remove()
  }

  // The JSON is placed within a script tag with type application/json
  _whitelist(){
    const jsonElId   = this.element.getAttribute('data-json-data-id')
    const jsonElData = document.getElementById(jsonElId)
    return (jsonElData ? JSON.parse(jsonElData.innerText) : [])
  }

  _addInputEl(evt){
    let inputEl = this._makeInputEl()
    inputEl.value = evt.detail.data.value
    this.element.querySelector('.tagify-current').appendChild(inputEl)
  }

  _removeInputEl(evt){
    const val =  evt.detail.data.value.replace(/\"/g, "\\\"")
    this.element.querySelector('.tagify-current input[value="'+val+'"]').remove()
  }

  _makeInputEl(){
    let wrapper = document.createElement('div')
    let str = this.templateTarget.innerHTML
    wrapper.innerHTML = str
    return wrapper.firstChild
  }
}