// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const Rails = require("@rails/ujs")
Rails.start()
window.Rails = Rails

import "@hotwired/turbo-rails"

require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
//const images = require.context('../images', true)
//const imagePath = (name) => images(name, true)

import "controllers"

require('../../assets/javascripts/o2v_user_interface_list')

document.addEventListener('turbo:load', function(){
  document.querySelectorAll('.o2v-list-frame').forEach(function(el){
    const obj = new O2vUserInterfaceListService({el: el})
    obj.initPage()
  })
})

// Return just the array of addresses that Addy returns
// Used by other code for the UI (ie. autoComplete.js)
// REF: https://www.addysolutions.com/dashboard
//
window.addyFetch = function(query){
  if(location.hostname.match(/^\d+/))
    console.warn('Reminder Addy could require a specific hostname to work')

  const el= document.querySelector('meta[name="addy-api"]')
  if(!el) return

  const apiKey   = el.dataset.key
  const endPoint = el.dataset.endpoint

  return fetch(`${endPoint}/search?s=${query}&key=${apiKey}&expostbox=true`)
    .then( response => response.json() )
    .then( json => {
      console.debug('Addy JSON response', json)
      return json['addresses']
    })
    .catch(error => {
      console.error('Addy API failure', error)
      return error
    })
}

window.addyAddress = function(addressID){
  const el= document.querySelector('meta[name="addy-api"]')
  if(!el) return

  const apiKey   = el.dataset.key
  const endPoint = el.dataset.endpoint

  return fetch(`${endPoint}/address/${addressID}?key=${apiKey}`)
    .then( response => response.json() )
    .then( json => {
      console.debug('Addy JSON response', json)
      return json
    })
    .catch(error => {
      console.error('Addy API failure', error)
      return error
    })
}