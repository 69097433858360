// Used for the HTML/JS index interface. Only supports clicking
// and redirecting the user at the moment.
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['row']

  clickRow(evt){

    if (evt.target.tagName == 'TD' && !evt.target.classList.contains('ignore-click')) {
      location.href = evt.currentTarget.dataset.pathValue
    }
  }
}