import debounce from "debounce"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'fieldList', 'template' ]

  connect() {
    this.element.addEventListener('change', (event) => {
      if(event.target.classList.contains('destroy')) {
        this.toggleDestroyedRow(event.target);
      }
    })

  }
  newCustomField() {
    const time  = new Date().getTime()

    event.preventDefault()

    var output = this.templateTarget.innerHTML.replace(/FIELD_INDEX/g, time)

    var node = document.createElement('tr')
    node.innerHTML = output

    this.fieldListTarget.appendChild(node)
  }

  toggleDestroyedRow(target) {
    const removing = target.checked
    const row = target.closest('tr')
    const fields = row.querySelectorAll('input, select')

    fields.forEach((field) => {
      field.disabled = removing && field != target
    })

    row.classList.toggle('removing', removing)
  }
}
