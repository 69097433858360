import { Controller } from 'stimulus'

/*
  Html/View helpers contained in Common::TabsInterfaceHelper
*/
export default class extends Controller {
  selectedClass = '-selected'

  static targets = ['tabButton', 'tabSection']

  connect(){
    this.element.controller = this
  }

  changeTab(evt){
    if(!evt.target.classList.contains(this.selectedClass)){
      this.openTo(this._elTaxIndex(evt.currentTarget))
    }
  }

  openTo(idx){
    this._clearSelected()
    this._select(idx)
  }

  _select(idx){
    this._markSelected(this.tabButtonTargets, idx)
    this._markSelected(this.tabSectionTargets, idx)
  }

  _clearSelected(){
    this.tabButtonTargets.forEach( btn => btn.classList.remove(this.selectedClass) )
    this.tabSectionTargets.forEach( btn => btn.classList.remove(this.selectedClass) )
  }

  _markSelected(ary, idx){
    ary.forEach( el => {
      this._elTaxIndex(el) == idx && el.classList.add(this.selectedClass)
    })
  }

  _elTaxIndex(el){
    return parseInt(el.getAttribute('data-tab-index'))
  }
}
