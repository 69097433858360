import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.element.popupController = this

    const popupState = this.element.dataset.state

    if(popupState == 'OPEN') this._toggle(true, false)

    this._log('Initialized with ID:', this.element.id)
  }

  open(){
    this._toggle(true)
  }

  close(_evt){
    this._toggle(false)
  }

  /* ---------------------------- */

  _toggle(show, closeOthers){
    if(show) this._closeOthers()
    this._log(`Toggle: ${show}`)

    this._toggleHidden(!show)
    this._toggleBodyClass(show)
  }

  _toggleBodyClass(enabled) {
    const body = document.getElementsByTagName('body')[0]

    if(enabled)
      body.classList.add('-showing-page-popup')
    else
      body.classList.remove('-showing-page-popup')
  }

  _toggleHidden(enabled) {
    if(enabled)
      this.element.classList.add('-hidden')
    else
      this.element.classList.remove('-hidden')
  }

  _closeOthers(){
    document.querySelectorAll('section.page-popup').forEach( el => el.controller?.close() )
  }

  _log() {console.debug('[D][PopupController]', ...arguments)}
}