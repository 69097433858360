import { Controller } from 'stimulus'
import autoComplete from '@tarekraafat/autocomplete.js'
import {highlightHint} from '../../utils/html_helpers.js'

// There are two other styles autoComplete.01.css and autoComplete.02.css
// Have our own styles at app/assets/stylesheets/global/autocomplete.scss
// import '@tarekraafat/autocomplete.js/dist/css/autoComplete.02.css'

export default class extends Controller {
  static targets = ['input']

  connect(){
    this._initAutoComplete()
  }

  disconnect(){
    this._debug('disconnect')

    this._autoCompleteJS.close()
    this._autoCompleteJS.unInit()
    delete this._autoCompleteJS
  }

  _initAutoComplete(){
    if(this._autoCompleteJS) return

    this._debug('Initialize autoCompleteJS')

    const config = {
      selector: () => { return this.inputTarget },
      placeholder: 'Enter address...',
      threshold: 3,
      debounce: 300,
      data: {
        src: this._addySearch.bind(this),
        keys: ['a'],
        cache: false
      },
      noResults: true,
      maxResults: 15,
      tabSelect: true
    }

    this._autoCompleteJS = new autoComplete(config);

    this._autoCompleteJS.input.addEventListener('selection', this._addySelection.bind(this))
  }

  _addySelection(evt){
    const feedback = evt.detail

    const selection = feedback.selection.value;

    if(typeof selection === 'undefined') {
      // Do nothing...
    } else {
      // Replace Input value with the selected value
      this._autoCompleteJS.input.value = selection.a;

      window.addyAddress(selection.id).then( addressParts => {
        this._populateForm(addressParts)
      })
    }
  }

  // Also updates delegator user based on state
  _populateForm(addressParts){
    this._updateFormInput('postcode', addressParts.postcode)
    this._updateFormInput('state_code', addressParts.state)

    this._updateFormInput('address', addressParts.address1)
    this._updateFormInput('suburb_name', addressParts.address2)

    this._updateDelegatorID(addressParts.state)
  }

  _addySearch(str){
    this._debug(`Addy Searching: ${str}`)
    return window.addyFetch(str)
  }

  // Used to set the delegator user for job
  _stateLookup(state){
    const el = document.querySelector('#state-user-json-data')

    if(el) {
      const userId = JSON.parse(el.innerText).stateUserLookup[state]
      this._debug(`Setting delegator user based on state (${state})...`)
      if(!userId) this._warn(`No state user present for ${state}`)
      return userId
    } else {
      this._warn('No state user lookup table present!')
      return null
    }
  }

  _updateDelegatorID(state){
    const userId = this._stateLookup(state)
    this._debug(`Setting delegator user ${userId}`)
    if(userId) this._updateFormInput('delegator_user_id', userId)
  }

  _updateFormInput(colName, val){
    const el = document.querySelector(`[name="job[${colName}]"]`)
    if(el) {
      el.value = val
      highlightHint(el)
    }
  }

  _showLoading(enabled) {
    this._addyProcessing = enabled
    const el = this.element.querySelector('.autoComplete_wrapper')
    el.classList.toggleClass('animated-loading-bar', enabled)
  }

  _debug(){
    console.debug('[D][AddyController]', ...arguments)
  }

  _warn(){
    console.warn('[W][AddyController]', ...arguments)
  }
}