import { Controller } from 'stimulus';
import { Liquid } from 'liquidjs';

export default class extends Controller {
  liquidEngine = new Liquid()

  static targets = [
    'template',
    'templateList',
    'emailBody',
    'emailSubject'
  ]

  connect(){
    if(!placeholderJSON){
      console.error('No placeholders found, no placeholderJSON defined on page')
    }
  }

  newForm(evt){
    evt.preventDefault()
    this.templateListTarget.appendChild(this._generateForm())
  }

  startEditing(evt){
    evt.target.value = this._getSiblingsValue(evt.target)
  }

  stopEditing(evt){
    this._setSiblingsValue(evt.target)
    evt.target.value = this._render(this._getSiblingsValue(evt.target))
  }

  _render(template){
    return this.liquidEngine.parseAndRenderSync(template, placeholderJSON)
  }

  _getSiblingsValue(el){
    return this._siblingInput(el).value
  }

  _setSiblingsValue(el){
    this._siblingInput(el).value = el.value
  }

  _siblingInput(el){
    const parentEl = el.closest('article.template')
    const sibClass = el.getAttribute('data-sibling-class')
    return parentEl.querySelector(sibClass+' > input')
  }

  _generateForm(){
    let el = document.createElement('article')
    el.classList.add('template')
    el.classList.add('-new')

    const time  = new Date().getTime()
    el.innerHTML = this.templateTarget.innerHTML.trim().replace(/FIELD_INDEX/g, time)

    return el
  }
}