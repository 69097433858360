import { Controller } from 'stimulus'
import Cookies from 'js-cookie'
/*
  Handles:
  - New note form and
  - Current note updates
*/
export default class extends Controller {
  static targets = [
    'newNoteOptions',
    'newNotePrivate', // Will trigger notify No-one if enabled
    'updateBtn',
    'emailOption',
    'notifyNoOneOption'
  ]

  initialize(){
    // Cookie key
    this.notifyNoOneKey = 'job-note--notify-no-one'
  }

  connect(){
    if(this._notifyNoOneEnabled()) {
      this._toggleNotifyNoOneOption({disabled: false, checked: true})
      this._toggleNoteOptions(true)
    }
  }

  // New form
  notePrivateChange(_evt){
    const shown = this.newNotePrivateTarget.checked

    // Only check 'Notify No-one' if it's not checked already
    if(!this.notifyNoOneOptionTarget.checked) {
      this._toggleNoteOptions(shown)
      this._toggleNotifyNoOneOption({disabled: shown, checked: shown})
    } else {
      this._toggleNotifyNoOneOption({disabled: shown})
    }
  }

  // If private is true the button will be disabled along
  // with all other email checkboxes
  toggleNotifyNoOne(evt){
    const checked = evt.target.checked

    this._setNotifyNoOneCookie(checked)
    this._toggleNoteOptions(checked)
  }

  // New form
  // Disable all "Email creation notices to" check boxes
  _toggleNoteOptions(shown){
    this.emailOptionTargets.forEach(e=> {
      if(shown) {
        e.dataset.wasChecked = e.checked
        e.setAttribute('disabled', 'disabled')
        e.checked = false
      } else {
        e.checked = (e.dataset.wasChecked == 'true')
        e.removeAttribute('disabled')
      }
    })

    this.newNoteOptionsTarget.classList.toggle('-disabled', shown)
  }

  // Current notes
  noteDeleteChange(evt){
    const confirmMsg = evt.target.dataset.confirm

    if(confirmMsg && confirm(confirmMsg)) {
      this._toggleUpdateButton(true)
    } else {
      evt.target.checked = false
    }
  }

  // Current notes
  noteImportantChange(_evt){
    this._toggleUpdateButton(true)
  }

  _toggleNotifyNoOneOption(opts){
    const el = this.notifyNoOneOptionTarget

    if(opts.disabled)
      el.setAttribute('disabled', 'disabled')
    else
      el.removeAttribute('disabled')

    if(typeof(opts.checked) != 'undefined')
      el.checked = opts.checked
  }

  // Current notes
  _toggleUpdateButton(enable){
    if(enable)
      this.updateBtnTargets.forEach(e=> e.removeAttribute('disabled'))
    else
      this.updateBtnTargets.forEach(e=> e.setAttribute('disabled', 'disabled'))
  }

  _setNotifyNoOneCookie(enabled){
    if(enabled)
      Cookies.set(this.notifyNoOneKey, '1')
    else
      Cookies.remove(this.notifyNoOneKey)
  }

  _notifyNoOneEnabled(){
    return Cookies.get(this.notifyNoOneKey) == '1'
  }
}